<template lang="pug">
// 新增聯絡人
v-card(:height="windowHeight-6" :max-height="windowHeight" outlined elevation='0')
  v-card-title.d-flex.justify-center {{$t('BULK_EDIT.TITLE')}}
  v-card(:height="windowHeight-150" :max-height="windowHeight-150"  outlined elevation='0'  class='scrollbar')
    v-card-text
      v-form(v-model="valid",ref="form",)
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_CONTACT.LAST_NAME')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.last_name.change'  :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='last_name', @change="visibleChange('last_name')")
            v-text-field.mb-3(prepend-icon='mdi-account',v-if='editContactData.last_name.change == 1' v-model="editContactData.last_name.value",:label= `$t('ADD_CONTACT.LAST_NAME')`,:rules="formRule.nameRules",validate-on-blur,required="required",large="large" ,ref='last_name_val',)
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_CONTACT.FIRST_NAME')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.first_name.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='first_name', @change="visibleChange('first_name')")
            v-text-field.mb-3(prepend-icon='mdi-account',v-if='editContactData.first_name.change == 1' v-model="editContactData.first_name.value",:label=`$t('ADD_CONTACT.FIRST_NAME')`,large="large",:rules="formRule.nameRules" ,ref='first_name_val',)
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('GENERAL.MAIL')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.email.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='email', @change="visibleChange('email')")
            v-text-field.no-border(
              v-if='editContactData.email.change == 1'
              v-model="editContactData.email.value",
              prepend-icon="mdi-email",
              :label= `$t('GENERAL.MAIL')`,
              :rules="formRule.emailRules",
              ,ref='email_val',
            )
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_CONTACT.MOBILE_PHONE')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.mobile_phone.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='mobile_phone', @change="visibleChange('mobile_phone')")
            v-row(no-gutters="no-gutters" v-if='editContactData.mobile_phone.change == 1')
              v-col(cols="2")
                v-icon(size='20') mdi-cellphone
              v-col
                vue-tel-input-vuetify.no-border(
                  v-model="editContactData.mobile_phone.value",
                  solo,
                  :label=`$t('ADD_CONTACT.MOBILE_PHONE')`,
                  :selectLabel=`$t('ADD_CONTACT.SELECT_LABEL')`,
                  placeholder='',
                  autocomplete='off',
                  :defaultCountry='getLocation()',
                  disabledFetchingCountry=true,
                  mode='international',
                  @validate="onPhoneCheck($event)",
                  @input="onPhoneInput",
                  :rules="formRule.phoneRules",
                  ,ref='mobile_phone_val',
                  )
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_CONTACT.OFFICE_PHONE')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.office_phone.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='office_phone', @change="visibleChange('office_phone')")
            v-text-field.no-border(
              v-if='editContactData.office_phone.change == 1'
              v-model="editContactData.office_phone.value",
              :rules="formRule.contentLenRule50",
              prepend-icon="mdi-phone",
              :label=`$t('ADD_CONTACT.OFFICE_PHONE')`,
              ,ref='office_phone_val',
            )
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_CONTACT.LEVEL')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.level.change'  :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='level', @change="visibleChange('level')")
            v-select.no-border(
              v-if='editContactData.level.change == 1'
              v-model='editContactData.level.value'
              :items='levelList' 
              item-text='name'
              item-value='key'
              prepend-icon='mdi-domain' 
              :label=`$t('ADD_CONTACT.LEVEL')`,
              ,ref='level_val',
              :rules="formRule.listRules",
            )
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('ADD_CONTACT.ORG_NAME')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.organization_set.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='organization_set', @change="visibleChange('organization_set')")
            v-combobox.no-border(
              v-if='editContactData.organization_set.change == 1'
              v-model="editContactData.organization_set.value",
              :items="organizationList",
              item-text="name",
              item-value="uqNumber",
              :search-input.sync="search",
              :loading="isLoading",
              prepend-icon="mdi-domain",
              :label=`$t('ADD_CONTACT.ORG_NAME')`,
              validate-on-blur,
              required="required",
              :rules="formRule.companyRule",
              ref='organization_set_val'
            )
              template(v-slot:append-outer)
                div(v-if="isNew")
                  v-icon(color="red", size="25") mdi-new-box
              template(v-slot:item="data")
                  div.ellipsis(v-if="data.item.id == 0")
                    span {{ data.item.name }}
                    v-icon(color="red", size="25") mdi-new-box
                  div.ellipsis(v-else)
                    span {{ data.item.name }}
        label.ellipsis.select-label-size(style='width: 300px;' ) Facebook
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.facebook.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='facebook', @change="visibleChange('facebook')")
            v-text-field.no-border(
              v-if='editContactData.facebook.change == 1'
              v-model="editContactData.facebook.value",
              prepend-icon="mdi-facebook",
              :rules="formRule.fbRules",
              label="Facebook",
              ,ref='facebook_val',
            )
        label.ellipsis.select-label-size(style='width: 300px;' ) Instagram
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.instagram_id.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='instagram_id', @change="visibleChange('instagram_id')")
            v-text-field.no-border(
              v-if='editContactData.instagram_id.change == 1'
              v-model="editContactData.instagram_id.value",
              prepend-icon="mdi-instagram",
              :rules="formRule.contentLenRule50",
              label="Instagram",
              ref='instagram_id_val',
            )
        label.ellipsis.select-label-size(style='width: 300px;' ) Line ID
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.line_id.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='line_id', @change="visibleChange('line_id')")
            v-text-field.no-border(
              v-if='editContactData.line_id.change == 1'
              v-model="editContactData.line_id.value",
              prepend-icon="fab fa-line",
              :rules="formRule.contentLenRule50",
              label="Line ID",
              ,ref='line_id_val',
            )
        label.ellipsis.select-label-size(style='width: 300px;' ) LinkedIn
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.linkedin.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='linkedin', @change="visibleChange('linkedin')")
            v-text-field.no-border(
              v-if='editContactData.linkedin.change == 1'
              v-model="editContactData.linkedin.value",
              prepend-icon="mdi-linkedin",
              :rules="formRule.linkRules",
              label="LinkedIn",
              ,ref='linkedin_val',
            )
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('GENERAL.CHANGE_OWNER')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.owner.change'  :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='owner', @change="visibleChange('owner')")
            v-autocomplete.no-border.height-48(:rules="formRule.fieldRules", v-if='editContactData.owner.change == 1' v-model='editContactData.owner.value' :items='ownerList' item-text='fullName' item-value='id' ,ref='owner_val', :label=`$t('GENERAL.CHANGE_OWNER_2')` return-object rounded solo prepend-icon='mdi mdi-account')
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CONTACT_DETAIL.BIRTHDAY')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.birthdate.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='birthdate', @change="visibleChange('birthdate')")
            //- v-text-field.w-100(v-if='editContactData.birthdate.change == 1' v-model='editContactData.birthdate.value' solo )
            v-menu(v-if='editContactData.birthdate.change == 1' ref="menu" v-model="menu" :z-index='10000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                    template(v-slot:activator="{ on, attrs }")
                        v-text-field.no-border.height-48(:rules="formRule.dateRules", prepend-icon='mdi-cake-variant',v-model='editContactData.birthdate.value' readonly :label=`$t('CONTACT_DETAIL.BIRTHDAY')`,  v-bind="attrs" v-on="on" ,ref='birthdate_val',)
                            template(v-slot:append)
                    v-date-picker.no-border( v-model='editContactData.birthdate.value' @input='menu = false' no-title scrollable) 
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CONTACT_DETAIL.FAX')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.fax.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='fax', @change="visibleChange('fax')")
            v-text-field.no-border.height-48(prepend-icon='mdi-tag',v-if='editContactData.fax.change == 1' v-model='editContactData.fax.value' :label=`$t('CONTACT_DETAIL.FAX')`,  :rules="formRule.contentLenRule20" ,ref='fax_val',)
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CONTACT_DETAIL.ADDRESS')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.billing_address.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='billing_address', @change="visibleChange('billing_address')")
            v-text-field.no-border.height-48(:rules="formRule.fieldRules", prepend-icon='mdi-map',v-if='editContactData.billing_address.change == 1' v-model='editContactData.billing_address.value' :label=`$t('CONTACT_DETAIL.ADDRESS')`, ,ref='billing_address_val',)
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CONTACT_DETAIL.CITY')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.city.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='city', @change="visibleChange('city')")
            v-text-field.no-border.height-48(prepend-icon='mdi-tag',v-if='editContactData.city.change == 1' v-model='editContactData.city.value' :label=`$t('CONTACT_DETAIL.CITY')`,  :rules="formRule.contentLenRule50" ,ref='city_val',)
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CONTACT_DETAIL.TITLE')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.title.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='title', @change="visibleChange('title')")
            v-text-field.no-border.height-48(prepend-icon='mdi-tag',v-if='editContactData.title.change == 1' v-model='editContactData.title.value' :label=`$t('CONTACT_DETAIL.TITLE')`,  :rules="formRule.contentLenRule50" ,ref='title_val',)
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CONTACT_DETAIL.COUNTRY')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.country.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='country', @change="visibleChange('country')")
            v-text-field.no-border.height-48(prepend-icon='mdi-tag',v-if='editContactData.country.change == 1' v-model='editContactData.country.value' :label=`$t('CONTACT_DETAIL.COUNTRY')`,  :rules="formRule.contentLenRule20" ,ref='country_val',)
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CONTACT_DETAIL.ZIP_CODE')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.zip_code.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='zip_code', @change="visibleChange('zip_code')")
            v-text-field.no-border.height-48(prepend-icon='mdi-tag',v-if='editContactData.zip_code.change == 1' v-model='editContactData.zip_code.value' :label=`$t('CONTACT_DETAIL.ZIP_CODE')`,  :rules="formRule.contentLenRule10" ,ref='zip_code_val',)
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CONTACT_DETAIL.DEPARTMENT')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.department.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='department', @change="visibleChange('department')")
            v-text-field.no-border.height-48(prepend-icon='mdi-tag',v-if='editContactData.department.change == 1' v-model='editContactData.department.value' :label=`$t('CONTACT_DETAIL.DEPARTMENT')`,  :rules="formRule.contentLenRule50" ,ref='department_val',)
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CONTACT_DETAIL.PROVINCE')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.province.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='province', @change="visibleChange('province')")
            v-text-field.no-border.height-48(prepend-icon='mdi-tag',v-if='editContactData.province.change == 1' v-model='editContactData.province.value' :label=`$t('CONTACT_DETAIL.PROVINCE')`,  :rules="formRule.contentLenRule50" ,ref='province_val',)
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CASE_DETAIL.RELATED_CAMPAIGN')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.primary_campaign_id.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='primary_campaign_id', @change="visibleChange('primary_campaign_id')")
            v-autocomplete.no-border(:rules="formRule.fieldRules", v-if='editContactData.primary_campaign_id.change == 1' v-model='editContactData.primary_campaign_id.value' solo :items='campaignList' item-text='name' item-value='id' ,ref='primary_campaign_id_val', :menu-props='{closeOnClick:true}' :label=`$t('CASE_DETAIL.RELATED_CAMPAIGN')` prepend-icon='mdi-tag')
        label.ellipsis.select-label-size(style='width: 300px;' ) {{$t('CONTACT_DETAIL.DESCRIPTION')}}
        div.bulk-edit-square(outline)
            v-select.select-text-size.no-border.no-padding(v-model='editContactData.description.change'  :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',ref='description', @change="visibleChange('description')")
            v-textarea.no-border.textarea-style(:rules="formRule.fieldRules", prepend-icon='mdi-tag',v-if='editContactData.description.change == 1' v-model='editContactData.description.value'  :label=`$t('CONTACT_DETAIL.DESCRIPTION')`, no-resize ,ref='description_val',)
        v-window
            customField(v-if='!isLoading' ref='CFRequired' DataClass = 'Contact'  :StageTypeId='null' )

  v-card-actions.px-2.py-4
    v-row
      v-col.pr-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark
        ) {{$t('GENERAL.CANCEL')}}
      v-col.pl-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onConfirm",
          width="100%",
          height="40",
          color="primary",
          depressed,
          :disabled="editBtn_loading || isLoading"
        ) 
          v-progress-circular(v-if='editBtn_loading' indeterminate size='20')
          .t-white(v-else) {{$t('GENERAL.CONFIRM')}}
  v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' persistent)
        message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
  v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
    error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import Vue from "vue";
import OrganizationDataService from "../../../services/OrganizationDataService";
import levelList from "@/array/the_levelList";
import i18n from '/common/plugins/vue-i18n.js';
import CampaignDataService from "@/services/CampaignDataService";
import messageDialog from '@/components/Dialog/messageDialog';
import bulkEditList from "@/array/the_bulkEditList";
import customField from "@/components/Dialog/BulkEdit/customFieldBulkEdit";
import errorDialog from "@/components/Dialog/errorDialog";

export default Vue.extend({
  props: {
    editItems: {
        type: Array,
        required: true,
    },
  },
  components: {
      customField,
      errorDialog,
      messageDialog,
  },
  data() {
    return {
      valid: true,
      isLoading: false,
      search: null,
      organizationList: [],
      // addContact_organization_set: this.editContactData.organization_set[0],
      addContact_organization_set: null,
      // comboText: this.editContactData.organization_set.length > 0 ? this.editContactData.organization_set[0].name : "",
      comboText: "",
      contactImage: null,
      levelList: levelList,
      formRule: {
        listRules: [(v) => /(^[0-9]\d*$)|null/.test(v) || i18n.t('RULE.RULE_R')],
        dateRules: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /^\d{4}-\d{2}-\d{2}$|null/.test(v) && !!v || i18n.t('RULE.RULE_DATE')
        ],
        fieldRules: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
        nameRules: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
        ],
        emailRules: [
          (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) ||  i18n.t('RULE.RULE_EMAIL'),
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
          (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
        phoneRules: [(v) => !!v || i18n.t('RULE.RULE_R'),],
        linkRules: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /(http|https):\/\/\S*|(^$)|null/.test(v) || i18n.t('RULE.RULE_HTTP'),
          (v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70')
        ],
        fbRules: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /(http|https):\/\/\S*|(^$)|null/.test(v) || i18n.t('RULE.RULE_HTTP'),
          (v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70')
        ],
        contentLenRule70: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70')
          ],
        contentLenRule50: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
        ],
        contentLenRule20: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')
          ],
        contentLenRule10: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /^.{0,10}$/.test(v) || i18n.t('RULE.RULE_10')
          ],
        photoRule: [
          () => { return (!this.photoSize || i18n.t('RULE.RULE_FILE_2'))},
          () => { return (!this.fileType || i18n.t('RULE.RULE_PHOTO'))},
        ],
        companyRule: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
          (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL')
        ],
      },
      isNumVaild: true,
      vue_tel: {
        isValid: true,
      },
      photoSize: false,
      fileType: false,
      editContactData: {
          organization_set: {change:0,value:''},
          first_name: {change:0,value:''},
          last_name: {change:0,value:''},
          email: {change:0,value:''},
          mobile_phone: {change:0,value:''},
          office_phone: {change:0,value:''},
          level: {change:0,value:''},
          facebook: {change:0,value:''},
          linkedin: {change:0,value:''},
          instagram_id: {change:0,value:''},
          line_id: {change:0,value:''},
          custom_field: {change:0,value:''},
          primary_campaign_id:{change:0,value:''},
          birthdate:{change:0,value:''},
          fax:{change:0,value:''},
          billing_address:{change:0,value:''},
          city:{change:0,value:''},
          title:{change:0,value:''},
          country:{change:0,value:''},
          zip_code:{change:0,value:''},
          department:{change:0,value:''},
          province:{change:0,value:''},
          description:{change:0,value:''},
          owner: {change:0,value:''},
        },
        windowHeight: window.innerHeight,
        processHeight: window.innerHeight-300,
        selectToChange: bulkEditList,
        selectToChangeNoDel: bulkEditList.filter(el=>el.key!=2),
        campaignList:[],
        ownerList: [],
        menu: false,
        messageDialog: false,
        message: '',
        bulkEditData:{},
        customFieldData:{},
        errorDialog: false,
        errorMessage: '',
        editBtn_loading: false,
        cfValid:true,
        isEditLoading:false,
    };
  },
  async created(){
        await CampaignDataService.selectlist().then((response)=>{
            this.campaignList = response.data;
        })
        OrganizationDataService.getchangeowner('contacts')
          .then((respose)=>{
              this.ownerList = respose.data;
              this.ownerList.forEach(item => {
                  item.fullName = item.last_name + ' ' + item.first_name;
              });
          });
  },
  methods: {
    visibleChange(blurVal){
        this.$refs[blurVal].blur();
        let focuVal = blurVal+'_val';
        setTimeout(()=>{
            try{
                this.$refs[focuVal].focus();
                this.$refs[focuVal].isMenuActive = true;
            }catch{
            // pass;
            }
        },100);
    },
    validate() {
      this.$refs.form.validate();
    },
    validateForm(){
      if(this.$refs.form.validate()){
        return true;
      }
      else{
        return false;
      }
    },
    async onConfirm() {
      this.formRule.phoneRules.push(() => {return (this.isNumVaild || i18n.t('CONTACT_DETAIL.ERROR_3'))});
      if(this.validateForm()){
        this.editBtn_loading = true;
        let bulkEditContactData = {};
        this.getResponsedata();
        if(this.cfValid == false){
          this.cfValid = true;
          return;
        }
        for(let i in this.editContactData){
            if(this.editContactData[i]['change']==1){
                bulkEditContactData[i] = this.editContactData[i]['value'];
                if(i == 'owner'){
                    bulkEditContactData[i] = this.editContactData[i]['value']['id'];
                }
                if(i == 'organization_set'){
                    if(typeof(this.editContactData.organization_set.value) == "string"){
                      bulkEditContactData[i] = {id: "-1", name: this.comboText, company_number: "0"};
                    }
                }
            }
            if(this.editContactData[i]['change']==2){
                bulkEditContactData[i] = null;
            }
        }
        let editItemName = '';
        let editItemNameArray = [];
        for(let i in this.editItems){
            editItemName = i > 0 ? editItemName +','+ this.editItems[i].full_name : editItemName + this.editItems[i].full_name ;
            editItemNameArray.push(this.editItems[i].id);
        }
        this.bulkEditData = {
            list:editItemNameArray,
            data:bulkEditContactData,
            cf:Object.keys(this.customFieldData).length > 0 ? this.customFieldData :null
        };
        if(Object.keys(this.customFieldData).length === 0 && Object.keys(this.bulkEditData.data).length === 0){
            this.showErrorDialog(i18n.t('BULK_EDIT.MESSAGE_EMPTY'));
            return;
        }
        this.showMessageDialog(this.editItems.length);
      }
    },
    showMessageDialog(message) {
        this.messageDialog = true;
        if(Object.keys(this.bulkEditData.data).length != 0 || Object.keys(this.customFieldData).length != 0)
        {
            this.message = `${i18n.t('BULK_EDIT.MESSAGE_DATA')}${message}${i18n.t('BULK_EDIT.MESSAGE_DATA_2')}`;
        }else
        {
            this.showErrorDialog(i18n.t('BULK_EDIT.MESSAGE_EMPTY'));
            this.messageDialog = false;
            return;
        }
    },
    async onEmitMessageDialog(val){
        if(val){
          if(!this.isEditLoading){
            this.isEditLoading = true;
            if(Object.keys(this.bulkEditData.data).length != 0 || Object.keys(this.customFieldData).length != 0){
                await(this.$emit('emitBulkEditDialog','editing',this.bulkEditData));
            }
          }
        }
        this.editBtn_loading = false;
        this.messageDialog = false;
    },
    showErrorDialog(message) {
        this.errorDialog = true;
        this.errorMessage = message;
    },
    onEmitErrorDialog() {
        this.errorDialog = false;
        this.editBtn_loading = false;
    },
    onCancel() {
        this.$emit('emitBulkEditDialog', false);
    },
    onPhoneCheck(event) {
      this.vue_tel = event;
      if (this.vue_tel.isValid == true) {
        this.isNumVaild = true;
      }
    },
    onPhoneInput() {
      if (this.isNumVaild == true) {
        if (this.editContactData.mobile_phone.value != this.vue_tel.number.input && this.editContactData.mobile_phone.value != "") {
          this.isNumVaild = false;
        }
      } else {
        if (this.editContactData.mobile_phone.value == "") {
          this.isNumVaild = true;
        }
      }
    },
    getResponsedata(){
      this.$refs.CFRequired.emitData();
      if(this.$refs.CFRequired.responsedata === false)
      {
        this.$refs.CFRequired.responsedata = null;
        this.editBtn_loading = false;
        this.cfValid = false;
        return;
      }
      this.customFieldData = this.$refs.CFRequired.responsedata;
    },
  },
  computed: {
    isNew: function(){
      if(this.addContact_organization_set == "" || this.addContact_organization_set == null){
        return false;
      }
      if(typeof (this.addContact_organization_set) === 'object'){
        if(this.addContact_organization_set.id == 0){
          return true;
        }
        else{
          return false;
        }
      }
      else{
        return true;
      }
    },
  },
  watch: {
    "errorDialog":function(){
        if(!this.errorDialog){
            this.editBtn_loading = false;
        }
    },
    async search(val) {
      var re = /^(?!\s*$).+/;
      var check = re.test(val);
      var checkLength = /^.{0,200}$/.test(val);
      this.comboText = "";
      if(check && val != null && checkLength){
        let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
        this.comboText = val;
        if(!this.isLoading && charNum>=2 && charNum==val.length ){
          this.isLoading = true;
          await OrganizationDataService.findOrganizationByNamefromDB(val)
          .then((response) => {
            this.organizationList = response.data;
            let num = 0
            this.organizationList.forEach((item)=>{
              item.uqNumber = num
              num ++
              })
          })
          .finally(() => (this.isLoading = false));
        }
        else {
          this.isLoading = false;
        }
      }
    },
    "editContactData.organization_set.value": function (){
      this.addContact_organization_set = this.editContactData.organization_set.value;
    },
  },
});
</script>
