<template lang="pug">
v-tab-item(key='detail' value='tab-1' :transition='false' :reverse-transition='false')
    div(v-if='cfItem.stage_id == "all" || cfItem.stage_id == showID || cfItem.stage_id == "common"' v-for='(cfItem,index) in process_array' )
        div(v-if='cfItem.id != "all"').mt-7.mb-4.d-flex.align-center.justify-space-between
            h3.d-flex.align-center
                .cube-icon.small.bg-purple.mr-2
                    v-icon(size='20' color='white') mdi-keyboard
                //- span(v-if='cfItem.id != "common" && cfItem.id != "all"') {{$t('CUSTOMFIELD.TITLE')}}－{{cfItem.id}}
                //- span(v-else) {{$t('CUSTOMFIELD.TITLE')}}
                span {{$t('CUSTOMFIELD.TITLE')}}
        v-row.sheet-box(v-if='cfItem.sort["1"] === undefined ').mb-4
            v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                    h5.ma-0.t-black {{$t('CUSTOMFIELD.NO_DATA')}}
        v-form(v-model='valid' ref='form')
            v-row.mb-4(no-gutters)
                v-col(cols='12' md='12' v-for='(item,index) in cfItem.sort' :key="item.index")
                    div(v-if='item.type=="bol"' :key='item.index')
                        label.ellipsis.select-label-size(style='width:300px;' :title='item.label' ) {{item.label}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-model='item.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            v-checkbox.py-0.ml-auto(v-if='item.change == 1' v-model='item.value' dense='dense' :hide-details='true'  :rules='item.mandatory  ? formRule.fieldRules: []' :ref='item.id+"_val"', :label=`item.label`)
                    div(v-if='item.type=="dat"' :key='item.index')
                        label.ellipsis.select-label-size(style='width: 300px;' :title='item.label') {{item.label}} 
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-if='!item.mandatory' v-model='item.change' :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            v-select.select-text-size.no-border.no-padding(v-else v-model='item.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            v-menu(v-if='item.change == 1' ref="menu" v-model="item.menu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                                template(v-slot:activator="{ on, attrs }")
                                    v-text-field(v-model='item.value'   :rules='item.mandatory  ? formRule.fieldRules: []' v-bind="attrs" v-on="on" :label=`item.label` prepend-icon='mdi-tag' ,:ref='item.id+"_val"' readonly :clearable='!item.mandatory')
                                v-date-picker(v-model='item.value' @input='item.menu = false' no-title scrollable)
                    div(v-if='item.type=="dtt"' :key='item.index')
                        label.ellipsis.select-label-size(style='width: 300px;' :title='item.label') {{item.label}} 
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-if='!item.mandatory' v-model='item.change' :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            v-select.select-text-size.no-border.no-padding(v-else v-model='item.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            v-menu(v-if='item.change == 1' ref="menu" v-model="item.menu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                                template(v-slot:activator="{ on, attrs }")
                                    v-text-field(v-model='item.value'   :rules='item.mandatory  ? formRule.fieldRules: []' v-bind="attrs" v-on="on" :label=`item.label` prepend-icon='mdi-tag' ,:ref='item.id+"_val"' readonly :clearable='!item.mandatory' @click='datepickerFocus(item)')
                                date-picker(v-model='item.value' type="datetime" valueType='format' inline :showSecond='false' format='YYYY-MM-DD HH:mm' @change="datepickerChange")
                    div(v-if='item.type=="str"' :key='item.index' )
                        label.ellipsis.select-label-size(style='width: 300px;' :title='item.label') {{item.label }}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-if='!item.mandatory' v-model='item.change' :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            v-select.select-text-size.no-border.no-padding(v-else v-model='item.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            v-text-field(v-if='item.change == 1' v-model='item.value' :rules='item.mandatory  ? formRule.fieldRules: []' :label=`item.label` prepend-icon='mdi-tag' ,:ref='item.id+"_val"')
                    div(v-if='item.type=="num"' :key='item.index')
                        label.ellipsis.select-label-size(style='width: 300px;' :title='item.label') {{item.label }}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-if='!item.mandatory' v-model='item.change' :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            v-select.select-text-size.no-border.no-padding(v-else v-model='item.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            v-text-field(v-if='item.change == 1' v-model.number='item.value' :rules='item.mandatory ? formRule.numMandatoryRule: []' :label=`item.label` prepend-icon='mdi-tag' ,:ref='item.id+"_val"' type='number' @input='item.value = check_num(item.value)')
                    div(v-if='item.type=="opt"' :key='item.index')
                        label.ellipsis.select-label-size(style='width: 300px;' :title='item.label') {{item.label }}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-if='!item.mandatory' v-model='item.change' :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            v-select.select-text-size.no-border.no-padding(v-else v-model='item.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            v-autocomplete.mt-1(v-model='item.value'
                                :items="item.list",
                                item-text="value",
                                item-value="key",
                                :rules='item.mandatory  ? formRule.fieldRules: []'
                                :class=' all_isReadonly ? "remove-arrow" : ""' 
                                :menu-props='{closeOnClick:true}',
                                v-if='item.change == 1',
                                :label=`item.label` prepend-icon='mdi-tag'
                                ,:ref='item.id+"_val"'
                                )
                    div(v-if='item.type=="mlt"' :key='item.index')
                        label.ellipsis.select-label-size(style='width: 300px;' :title='item.label') {{item.label }}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-if='!item.mandatory' v-model='item.change' :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            v-select.select-text-size.no-border.no-padding(v-else v-model='item.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            v-autocomplete.mt-1(v-model='item.value'
                                :items="item.list",
                                item-text="value",
                                item-value="key",
                                :rules='item.mandatory  ? formRule.optMandatoryRule: []'
                                :class=' all_isReadonly ? "remove-arrow" : ""' 
                                :menu-props='{closeOnClick:true}',
                                v-if='item.change == 1',
                                :label=`item.label` prepend-icon='mdi-tag'
                                ,:ref='item.id+"_val"'
                                multiple)
                    div(v-if='item.type=="tst"' :key='item.index')
                        label.ellipsis.select-label-size(style='width: 300px;' :title='item.label') {{item.label }}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-if='!item.mandatory' v-model='item.change' :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            v-select.select-text-size.no-border.no-padding(v-else v-model='item.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            .d-flex.justify-between(v-if='item.change == 1')
                                v-icon mdi-tag
                                p.d-flex.align-center.input-has-label.pb-0.mb-0
                                    treeselect.treeselect-menu-black.bulkEditTreeselect(
                                        :openOnFocus='true'
                                        v-model='item.value'
                                        :options="item.list",
                                        :multiple="false"
                                        :disable-branch-nodes="true"
                                        placeholder=''
                                        noResultsText='No data available'
                                        item-text="value",
                                        item-value="key", solo='solo' 
                                        :rules='formRule.optMandatoryRule'
                                        :ref='item.id+"_val"'
                                        @input='changeTreeValue(item)'
                                        @select='closeTreeMenu(item)'
                                        :title='item.name'
                                        :clearable='false'
                                    )
                                        div(slot="option-label" slot-scope="{ node }" :title='node.raw.label') {{ node.raw.label }}
                                        div(slot="value-label" slot-scope="{ node }") {{ node.raw.customLabel }}
                                v-text-field(v-if='item.change == 1' v-model='item.value' :rules='item.mandatory  ? formRule.fieldRules: []' :label=`item.label` :ref='item.id+"_val"')
                    div(v-if='item.type=="txt"' :key='item.index' )
                        label.ellipsis.select-label-size(style='width: 300px' :title='item.label') {{item.label}}
                        div.bulk-edit-square(outline)
                            v-select.select-text-size.no-border.no-padding(v-if='!item.mandatory' v-model='item.change' :items='selectToChange' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            v-select.select-text-size.no-border.no-padding(v-else v-model='item.change' :items='selectToChangeNoDel' item-text='name' item-value='key' :menu-props='{closeOnClick:true}',:ref='item.id', @change="visibleChange(item.id)")
                            v-textarea.textarea-style(v-if='item.change == 1' v-model='item.value' rows="3" row-height="20" no-resize dense='dense'  :rules='item.mandatory ? formRule.fieldRules: []' :label=`item.label` prepend-icon='mdi-tag' ,:ref='item.id+"_val"')
    v-dialog(v-model='errorDialog' width='360'  content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import CustomFieldDataService from "@/services/CustomFieldDataService";
    import TreeOptionsDataService from "@/services/TreeOptionsDataService";
    import errorDialog from '@/components/Dialog/errorDialog';
    import i18n from '/common/plugins/vue-i18n.js';
    import bulkEditList from "@/array/the_bulkEditList";
    import Treeselect from '@riophae/vue-treeselect';
    import DatePicker from 'vue2-datepicker';

    export default Vue.extend({
        props: {    
            DataClass :{
                type: String,
                required: true,
            },  
            StageTypeId :{
                required: false,
            },
            stageList :{
                type: Array,
            },
        },
        components: {
            errorDialog,
            Treeselect,
            DatePicker,
        },
        data() {
            return {
                    errorDialog: false,
                    errorMessage: '',
                    dataValue:null,
                    valid: true,    
                    menu: false, 
                    DataDefinition: null,
                    all_DataDefinition: null,
                    isReadonly: true, 
                    all_isReadonly: true, 
                    formRule: {
                        fieldRules: [(v) => !!v || i18n.t('RULE.RULE_R')],
                        numMandatoryRule: [(v) => (v === 0 || !!v) || i18n.t('RULE.RULE_R')],
                        optMandatoryRule: [(v) => (v || []).length >0 || i18n.t('RULE.RULE_R')],
                    },
                    oringal_data : null,
                    process_array:[],
                    selectToChange: bulkEditList,
                    selectToChangeNoDel: bulkEditList.filter(el=>el.key!=2),
                    showID:null,
                    emptyData:[],
                    responsedata:null,
                    openDatePicker:null,
                }
            },
        async created(){
            if(this.StageTypeId){
                this.showID = this.StageTypeId;
            }
            await this.get_ts_list();
            await this.get_cf_detail();
        },
        methods:
        {
            datepickerFocus(item){
                this.openDatePicker = item;
            },
            datepickerChange(value, type){
                if (type === 'minute') {
                    this.openDatePicker.menu = false;
                }
            },
            changeTreeValue(item){
                let treeSelectList = this.treeSelectData.filter(el=>el.id==item.tree_select_id)[0].option_set;
                item.name = treeSelectList[item.value];
            },
            closeTreeMenu(item){
                item.menu = false;
            },
            async get_ts_list(){
                await TreeOptionsDataService.list().then((response)=>{
                    this.treeSelectData = response.data;
                })
            },
            check_num(num){
                if(num === ""){
                    return null;
                }
                else{
                    return num;
                }
            },
            visibleChange(blurVal){
                this.$refs[blurVal][0].blur()
                let focuVal = blurVal+'_val'
                setTimeout(()=>{
                    try{
                        this.$refs[focuVal][0].focus();
                        this.$refs[focuVal][0].isMenuActive = true;
                    }catch{
                    // pass;
                    }
                },100)
            },
            async get_cf_detail(){
                this.DataDefinition = null;
                this.process_array = [];

                await CustomFieldDataService.getCustomFieldDetail(this.DataClass)
                .then(response => {
                    if(this.StageTypeId)
                    {
                        for(let i in response.data[0].definition)
                        {
                            if(response.data[0].definition[i].stage_type_id!='all')
                            {
                                this.DataDefinition = response.data[0].definition[i].data;
                                let stage_name = ""
                                try{
                                     stage_name = this.stageList.filter(el=>el.id==response.data[0].definition[i].stage_type_id)[0].stage_type_name
                                }
                                catch{
                                    // 
                                }
                                this.process_array.push({'pos':1,'def':this.DataDefinition,'sort':{},'id':stage_name,'stage_id':response.data[0].definition[i].stage_type_id});
                            }
                            if(response.data[0].definition[i].stage_type_id=='all')
                            {
                                this.all_DataDefinition = response.data[0].definition[i].data;
                                this.process_array.push({'pos':2,'def':this.all_DataDefinition,'sort':{},'id':'all','stage_id':'all'});
                            }
                        }
                            this.process_array.sort(function(a, b){
                                return b.pos - a.pos;
                            })
                    }else
                    {
                        this.DataDefinition = response.data[0].definition;
                        this.process_array.push({'def':this.DataDefinition,'sort':{},'id':'common','stage_id':'common'});
                    }
                }).catch(error => {
                    this.showErrorDialog(error);
                })
                for(let s in this.process_array)
                {
                    let def = this.process_array[s]['def'];
                    let sort = this.process_array[s]['sort'];
                    for (let i in def){
                        let sequenceNumber=String(def[i]['sequence_number']);
                        Vue.set(sort, sequenceNumber ,def[i]);
                    }
                    for (let i in def){
                        let sequenceNumber=String(def[i]['sequence_number']);
                        Vue.set(sort[sequenceNumber], 'type', i.slice(0,3));
                        Vue.set(sort[sequenceNumber], 'id', i);
                        if(i.slice(0,3) == "opt" || i.slice(0,3) == "mlt"){
                            let optList = Object.entries(sort[sequenceNumber].option_set).map((arr) => (
                                {
                                    key: arr[0],
                                    value: arr[1],
                                }));
                            Vue.set(sort[sequenceNumber], 'list', optList);
                        } 
                        if(i.slice(0,3) == "tst"){
                            let optList = this.treeSelectData.filter(el=>el.id == def[i].tree_select_id)[0].value;
                            optList = this.kickOutChildren(optList);
                            Vue.set(sort[sequenceNumber], 'list', optList);
                        } 
                        if(i.slice(0,3) == "dat" || i.slice(0,3) == "dtt"){
                            Vue.set(sort[sequenceNumber], 'menu', false);
                        }
                        Vue.set(sort[sequenceNumber], 'change', 0);
                        Vue.set(sort[sequenceNumber], 'value', '');
                    }
                }
                this.emptyData = JSON.parse(JSON.stringify(this.process_array))
            },
            kickOutChildren(data){
                return data.map((el) => {
                        if(el.children && el.children.length>0){
                            return {
                                ...el,
                                children: this.kickOutChildren(el.children),
                            };
                        }else{
                            delete el.children;
                        }
                        return el;
                });
            },
            validateForm(){
                if(this.DataClass == 'Contact' || this.DataClass == 'Organization')
                {
                    if(this.$refs.form[0].validate())
                    {
                        return true;
                    }
                    else
                    {
                        return false;
                    }
                }else{
                    try{
                        if(this.$refs.form[0].validate() && this.$refs.form[1].validate())
                        {
                            return true;
                        }
                        else
                        {
                            return false;
                        }
                    }catch{
                        if(this.$refs.form === undefined || this.$refs.form.length === 0)
                            return true;
                        else{
                            if(this.$refs.form[0].validate())
                            {
                                return true;
                            }
                            else
                            {
                                return false;
                            }
                        }
                    }
                }               
            },
            emitData() {
                if(this.process_array.length == 0){
                    if(this.StageTypeId){
                        return [];
                    }else{
                        return {};
                    }
                }
                if (this.validateForm()) {
                    let editData = {};
                    if(this.StageTypeId)
                    {
                        this.oringal_data = []
                        for(let i in this.process_array){
                                let dataChange = {};
                                for(let s in this.process_array[i].sort){
                                    if(this.process_array[i].sort[s].change == 1){
                                        if((this.process_array[i].sort[s].id).includes("bol") && this.process_array[i].sort[s].value != true){
                                            dataChange[this.process_array[i].sort[s].id] = false;
                                        }
                                        else if((this.process_array[i].sort[s].id).includes("num") && this.process_array[i].sort[s].value == ""){
                                            dataChange[this.process_array[i].sort[s].id] = null;
                                        }
                                        else{
                                            dataChange[this.process_array[i].sort[s].id] = this.process_array[i].sort[s].value
                                        }
                                    }
                                    if(this.process_array[i].sort[s].change == 2){
                                        if((this.process_array[i].sort[s].id).includes("num")){
                                            dataChange[this.process_array[i].sort[s].id] = null;
                                        }
                                        else{
                                            dataChange[this.process_array[i].sort[s].id] = "";
                                        }
                                    }
                                }
                                if(Object.keys(dataChange).length > 0){
                                    this.oringal_data.push({"data":dataChange,"stage_type_id":this.process_array[i].stage_id});
                                }
                        }
                        editData = this.oringal_data
                    }else{
                        let dataChange = {};
                        for(let s in this.process_array[0].sort){
                            if(this.process_array[0].sort[s].change == 1){
                                if((this.process_array[0].sort[s].id).includes("bol") && this.process_array[0].sort[s].value != true){
                                    dataChange[this.process_array[0].sort[s].id] = false;
                                }
                                else if((this.process_array[0].sort[s].id).includes("num") && this.process_array[0].sort[s].value == ""){
                                    dataChange[this.process_array[0].sort[s].id] = null;
                                }
                                else{
                                    dataChange[this.process_array[0].sort[s].id] = this.process_array[0].sort[s].value
                                }
                            }
                            if(this.process_array[0].sort[s].change == 2){
                                if((this.process_array[0].sort[s].id).includes("num")){
                                    dataChange[this.process_array[0].sort[s].id] = null;
                                }
                                else{
                                    dataChange[this.process_array[0].sort[s].id] = "";
                                }
                            }
                        }
                        editData = dataChange
                    }
                    this.responsedata = editData;
                }else{
                    this.responsedata = false;
                }
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorMessage = false;
            },            
        },
        watch: {
            "StageTypeId": async function() {
                if(this.StageTypeId){
                    let index = this.process_array.map(e => e.stage_id).indexOf(this.showID)
                    if(index != -1 && this.process_array[index].pos == 1 && this.process_array[index].stage_id == this.showID){
                        for(let s in this.process_array[index].def){
                            this.process_array[index].def[s].change = 0;
                            this.process_array[index].def[s].value = "";
                        }
                    }
                    await (this.showID = this.StageTypeId);
                }
            },
            isReadonly(val){
                this.removeMenu(val);
            },
        }
    });
</script>
<style lang="css">
.nopadding{
    padding-left: 0;
}
</style>